.p-datatable {
  overflow-x: scroll; }

.button-group-table {
  margin: auto; }
  .button-group-table .button-table {
    width: 24%;
    border-radius: 2rem;
    display: table-cell;
    margin-right: 1px;
    padding: 1px 0px; }
  .button-group-table .btn-asign {
    background-color: #c2004e; }
  .button-group-table .btn-see {
    background-color: #034c0b; }
  .button-group-table .btn-edit {
    background-color: #0048ff; }
  .button-group-table .btn-return {
    background-color: #b300ff; }
  .button-group-table .btn-close {
    background-color: #f8a100; }
